<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="รหัส"
        v-model="formData.code"
        :validations="[
          {text: 'required!', value: v.formData.code.$dirty && !v.formData.code.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && !v.formData.name.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="Client ID"
        v-model="formData.clientId"
        :validations="[
          {text: 'required!', value: v.formData.clientId.$dirty && !v.formData.clientId.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="Client Secret"
        v-model="formData.clientSecret"
        :validations="[
          {text: 'required!', value: v.formData.clientSecret.$dirty && !v.formData.clientSecret.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="ApiKey"
        v-model="formData.apiKey"
        :validations="[
          {text: 'required!', value: v.formData.apiKey.$dirty && !v.formData.apiKey.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="Redirect Url"
        v-model="formData.redirectUrl"
        :validations="[
          {text: 'required!', value: v.formData.redirectUrl.$dirty && !v.formData.redirectUrl.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="AccessToken"
        v-model="formData.accessToken">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="RefreshToken"
        v-model="formData.refreshToken">
      </sgv-input-text>

      <sgv-input-date
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="ExpiresIn"
        v-model="formData.expiresIn">
      </sgv-input-date>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="Printer ID"
        v-model="formData.printerId"
        :validations="[
          {text: 'required!', value: v.formData.printerId.$dirty && !v.formData.printerId.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="Printer Email"
        v-model="formData.printerEmail"
        :validations="[
          {text: 'required!', value: v.formData.printerEmail.$dirty && !v.formData.printerEmail.required},
          {text: 'invalid email!', value: v.formData.printerEmail.$dirty && !v.formData.printerEmail.email}
        ]">
      </sgv-input-text>

      <DetailFormDropdownEmail
        :disabled="$auth.disabled(method)"
        :templateType="templateType"
        :deviceType="deviceType"
        class="col-12"
        label="Sender Email"
        select="id"
        v-model="formData.deviceEmailId"
        :validations="[
          {text: 'required!', value: v.formData.deviceEmailId.$dirty && !v.formData.deviceEmailId.required}
        ]">
      </DetailFormDropdownEmail>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ใช้งาน"
          inline
          v-model="formData.isActive">
        </sgv-input-check>
      </div>
    </div>
  </div>
</template>

<script>
import DetailFormDropdownEmail from './DetailFormDropdownEmail.vue'

export default {
  components: {
    DetailFormDropdownEmail
  },
  props: {
    templateType: {
      type: String,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  }
}
</script>

<style lang="css">
</style>
