var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รหัส","validations":[
        {text: 'required!', value: _vm.v.formData.code.$dirty && !_vm.v.formData.code.required}
      ]},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.v.formData.name.$dirty && !_vm.v.formData.name.required}
      ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Client ID","validations":[
        {text: 'required!', value: _vm.v.formData.clientId.$dirty && !_vm.v.formData.clientId.required}
      ]},model:{value:(_vm.formData.clientId),callback:function ($$v) {_vm.$set(_vm.formData, "clientId", $$v)},expression:"formData.clientId"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Client Secret","validations":[
        {text: 'required!', value: _vm.v.formData.clientSecret.$dirty && !_vm.v.formData.clientSecret.required}
      ]},model:{value:(_vm.formData.clientSecret),callback:function ($$v) {_vm.$set(_vm.formData, "clientSecret", $$v)},expression:"formData.clientSecret"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ApiKey","validations":[
        {text: 'required!', value: _vm.v.formData.apiKey.$dirty && !_vm.v.formData.apiKey.required}
      ]},model:{value:(_vm.formData.apiKey),callback:function ($$v) {_vm.$set(_vm.formData, "apiKey", $$v)},expression:"formData.apiKey"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Redirect Url","validations":[
        {text: 'required!', value: _vm.v.formData.redirectUrl.$dirty && !_vm.v.formData.redirectUrl.required}
      ]},model:{value:(_vm.formData.redirectUrl),callback:function ($$v) {_vm.$set(_vm.formData, "redirectUrl", $$v)},expression:"formData.redirectUrl"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"AccessToken"},model:{value:(_vm.formData.accessToken),callback:function ($$v) {_vm.$set(_vm.formData, "accessToken", $$v)},expression:"formData.accessToken"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"RefreshToken"},model:{value:(_vm.formData.refreshToken),callback:function ($$v) {_vm.$set(_vm.formData, "refreshToken", $$v)},expression:"formData.refreshToken"}}),_c('sgv-input-date',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ExpiresIn"},model:{value:(_vm.formData.expiresIn),callback:function ($$v) {_vm.$set(_vm.formData, "expiresIn", $$v)},expression:"formData.expiresIn"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Printer ID","validations":[
        {text: 'required!', value: _vm.v.formData.printerId.$dirty && !_vm.v.formData.printerId.required}
      ]},model:{value:(_vm.formData.printerId),callback:function ($$v) {_vm.$set(_vm.formData, "printerId", $$v)},expression:"formData.printerId"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Printer Email","validations":[
        {text: 'required!', value: _vm.v.formData.printerEmail.$dirty && !_vm.v.formData.printerEmail.required},
        {text: 'invalid email!', value: _vm.v.formData.printerEmail.$dirty && !_vm.v.formData.printerEmail.email}
      ]},model:{value:(_vm.formData.printerEmail),callback:function ($$v) {_vm.$set(_vm.formData, "printerEmail", $$v)},expression:"formData.printerEmail"}}),_c('DetailFormDropdownEmail',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"templateType":_vm.templateType,"deviceType":_vm.deviceType,"label":"Sender Email","select":"id","validations":[
        {text: 'required!', value: _vm.v.formData.deviceEmailId.$dirty && !_vm.v.formData.deviceEmailId.required}
      ]},model:{value:(_vm.formData.deviceEmailId),callback:function ($$v) {_vm.$set(_vm.formData, "deviceEmailId", $$v)},expression:"formData.deviceEmailId"}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ใช้งาน","inline":""},model:{value:(_vm.formData.isActive),callback:function ($$v) {_vm.$set(_vm.formData, "isActive", $$v)},expression:"formData.isActive"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }